// extracted by mini-css-extract-plugin
export const article = "d_q";
export const article_date = "d_r";
export const article_title = "d_s";
export const article_content = "d_t";
export const news = "d_v";
export const forArticle = "d_w";
export const news_heading = "d_x";
export const news_btn = "d_y";
export const news_wrap = "d_z";
export const news_list = "d_B";