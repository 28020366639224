import * as React from 'react'
import { Link } from 'gatsby'
import { graphql } from 'gatsby'
import { Container, Button } from 'react-bootstrap'
import Layout from '~components/Layout'
import { NewsItem } from '~components/NewsItem'
import SEO from '~components/seo'
import cn from 'classnames'
import {
  article,
  article_date,
  article_title,
  article_content,
  news,
  forArticle,
  news_heading,
  news_btn,
  news_wrap,
  news_list,
} from './style.module.scss'

const Article = ({ data: { publication, other } }) => {
  const { id, title, brief, descr, date } = publication

  return (
    <Layout>
      <Container>
        <SEO title={`${title} – News`} description={brief} />
        <section className={article}>
          <p className={article_date}>{date}</p>
          <h1 className={article_title}>{title}</h1>
          <div className={article_content} dangerouslySetInnerHTML={{ __html: descr }} />
        </section>
        <section className={cn(news, forArticle)}>
          <h2 className={news_heading}>Other Articles</h2>
          <Button as={Link} variant="white" className={news_btn} to="/news">
            See more
          </Button>
          <div className={news_wrap}>
            <div className={news_list}>
              {other.edges
                .filter((item) => item.id !== id)
                .map(({ node: { id, slug, date, title, brief } }) => (
                  <NewsItem key={id} slug={slug} title={title} brief={brief} date={date} isInline />
                ))}
            </div>
          </div>
        </section>
      </Container>
    </Layout>
  )
}
export default Article

export const pageQuery = graphql`
  query newsQuery($slug: String!) {
    publication: newsJson(slug: { eq: $slug }) {
      id
      title
      descr
      brief
      date
    }
    other: allNewsJson {
      edges {
        node {
          id
          date
          brief
          title
          slug
        }
      }
    }
  }
`
